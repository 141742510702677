import Vue from "vue";
import VueRouter from "vue-router";

import Store from "@/store/index";
import AdministratorView from "@/views/administration/AdministratorView";

import store from "../store/index.js";
import { sync } from "vuex-router-sync";
import loadSettings from "./helpers/load-settings";
import loadSettingsAll from "./helpers/load-settings-all";

Vue.use(VueRouter);

let searchRegex = /\/search\/(?<category>.*)\/.*/;
const DEFAULT_TITLE = "MyBREB - Merchants, Listings, $avings";

async function checkAdministrator() {
    let groups = Store.getters["api/acl/getSelfGroups"];
    if (!groups || groups.length === 0) return false;

    return groups.indexOf("administrators") >= 0;
}

async function isLoggedIn(to, from, next) {
    const loggedIn = store.getters["user/loggedIn"];

    if (loggedIn) {
        next();
    } else {
        store.dispatch("user/getUser").finally(() => {
            next();
        });
    }
}

const routes = [
    {
        path: "/property/listing/:id",
        name: "PropertyListingView",
        component: () => import("@/refactor/views/RealEstateListingView.vue"),
        props: function (route) {
            const props = { ...route.params };
            props.id = +props.id;
            return props;
        },
    },

    {
        path: "/item/listing/:id",
        name: "ItemListingView",
        component: () => import("@/refactor/views/ItemListingView.vue"),
        props: function (route) {
            const props = { ...route.params };
            props.id = +props.id;
            return props;
        },
    },
    {
        path: "/event/listing/:id",
        name: "EventListingView",
        component: () => import("@/refactor/views/EventListingView.vue"),
        props: function (route) {
            const props = { ...route.params };
            props.id = +props.id;
            return props;
        },
    },

    {
        path: "/classified/listing/:id",
        name: "PersonalListingView",
        component: () => import("@/refactor/views/JobListingView.vue"),
        props: function (route) {
            const props = { ...route.params };
            props.id = +props.id;
            return props;
        },
    },

    {
        path: "/merchant/listing/:id",
        name: "MerchantListingView",
        component: () => import("@/refactor/views/MerchantListingView.vue"),
        props: function (route) {
            const props = { ...route.params };
            props.id = +props.id;
            return props;
        },
    },
    {
        path: "/realtor/listing/:ownerId",
        name: "RealtorListingView",
        component: () => import("@/refactor/views/RealtorListingView.vue"),
        props: true,
    },
    {
        path: "/city_service/listing/:id",
        name: "CityServiceListingView",
        component: () => import("@/refactor/views/CityServiceListingView.vue"),
        props: function (route) {
            const props = { ...route.params };
            props.id = +props.id;
            return props;
        },
    },

    {
        path: "/product/:shopId/:id",
        name: "ProductView",
        component: () => import("@/refactor/views/ProductView.vue"),
        props: function (route) {
            const props = { ...route.params };
            return props;
        },
    },
    {
        name: "SearchByPhone",
        path: "/search/phone/:code/:number",
        component: () => import("@/views/SearchByPhone.vue"),
        props: true,
    },
    {
        name: "SearchBrokerage",
        path: "/search/brokerage",
        component: () => import("@/views/SearchBrokerage/SearchBrokerageView.vue"),
        props: true,
    },

    {
        name: "BrokerageProfileView",
        path: "/search/brokerage/:id",
        component: () => import("@/views/BrokerageProfile/BrokerageProfileView.vue"),
        props: true,
    },

    {
        name: "BrokerProfileView",
        path: "/search/broker/:id",
        component: () => import("@/views/BrokerProfile/BrokerProfileView.vue"),
        props: true,
    },

    {
        name: "BrokerageListingsView",
        path: "/search/brokerage/:id/listings",
        component: () => import("@/views/BrokerageListings/BrokerageListingsView.vue"),
        props: true,
    },
    {
        name: "BrokerageCreate",
        path: "/dashboard/brokerage/create",
        component: () => import("@/views/dashboard/CreateBrokerage.vue"),
        props: true,
    },
    {
        name: "BrokerageList",
        path: "/dashboard/brokerage/list",
        component: () => import("@/views/dashboard/MyBrokerages/MyBrokerages.vue"),
        beforeEnter: isLoggedIn,
        props: true,
    },
    {
        name: "BrokerageEdit",
        path: "/dashboard/brokerage/edit/:id",
        component: () => import("@/views/dashboard/EditBrokerage/EditBrokerage.vue"),
        props: true,
    },
    {
        name: "Bookmarks",
        path: "/dashboard/bookmarks",
        component: () => import("@/views/dashboard/bookmarks/Bookmarks/Bookmarks.vue"),
        meta: { restricted: true, navigationAllowed: true },
        props: true,
    },
    {
        name: "StripeConnect",
        path: "/dashboard/stripe/connect",
        redirect: (to) => {
            let redirectKey = JSON.parse(localStorage.getItem("redirect-key"));
            if (redirectKey && redirectKey.action === "shop_registration") {
                return {
                    path: "/dashboard/marketplace/createShop",
                };
            } else if (redirectKey && redirectKey.action === "partner_application") {
                localStorage.removeItem("redirect-key");
                return {
                    path: "/dashboard/manager-account",
                };
            } else {
                localStorage.removeItem("redirect-key");
                return {
                    path: "/dashboard/profile",
                };
            }
        },
        props: true,
    },
    {
        name: "SearchContainerV2",
        path: "/search/v2",
        props: true,
        component: () => import("@/refactor/views/SearchView.vue"),
        children: [
            {
                name: "RealtorSearchV2",
                path: "realtor/:subcategory",
                props: true,
                component: () => import("@/refactor/search/types/RealtorSearch.vue"),
            },
            {
                name: "PropertySearchV2",
                path: "property/:subcategory",
                props: true,
                component: () => import("@/refactor/search/types/PropertySearch.vue"),
            },
            {
                name: "MerchantSearchV2",
                path: "merchant/:subcategory",
                props: true,
                component: () => import("@/refactor/search/types/MerchantSearch.vue"),
            },
            {
                name: "ItemSearchV2",
                path: "item/:subcategory",
                props: true,
                component: () => import("@/refactor/search/types/ItemSearch.vue"),
            },
            {
                name: "PersonalSearchV2",
                path: "classified/:subcategory",
                props: true,
                component: () => import("@/refactor/search/types/ClassifiedSearch.vue"),
            },
            {
                name: "EventSearchV2",
                path: "event/:subcategory",
                props: true,
                component: () => import("@/refactor/search/types/EventSearch.vue"),
            },
        ],
    },
    {
        path: "/",
        name: "Home",
        component: () => import("@/refactor/views/Home.vue"),
        beforeEnter: loadSettings,
        meta: {
            keepAlive: true,
            navigationAllowed: false,
        },
    },
    {
        path: "/about",
        name: "About",
        component: () => import("@/views/root/AboutView/AboutView.vue"),
        meta: {
            keepAlive: true,
        },
    },
    {
        path: "/articles",
        component: () => import("@/views/root/Articles.vue"),
        beforeEnter: loadSettings,
        name: "Articles",
        props: true,
    },
    // {
    //     path: "/subscriptions",
    //     name: "Subscriptions",
    //     component: () => import("@/views/Subscriptions/SubscriptionPackages.vue"),
    //     meta: {},
    // },
    {
        path: "/subscription",
        name: "Subscription",
        component: () => import("@/views/Subscriptions/SubscriptionView/SubscriptionView.vue"),
        meta: {},
    },
    {
        path: "/subscription-map",
        name: "SubscriptionMapView",
        component: () => import("@/views/root/SubscriptionMapView/SubscriptionMapView.vue"),
        meta: {},
    },

    {
        path: "/checkout",
        name: "CheckoutView",
        component: () => import("@/views/Subscriptions/CheckoutView/CheckoutView.vue"),
        meta: {},
    },
    {
        name: "Boards",
        path: "/dashboard/boards",
        component: () => import("@/views/dashboard/Boards/Boards.vue"),
        props: true,
    },
    {
        path: "/dashboard/login",
        name: "LoginFrame",
        component: () => import("@/views/user/LoginView.vue"),
        meta: {
            navigationAllowed: false,
        },
        children: [
            {
                path: "",
                name: "Login",
                component: () => import("@/views/user/login/LoginForm.vue"),
                meta: {
                    navigationAllowed: false,
                },
            },
            {
                path: "forgot-password",
                component: () => import("@/views/user/login/PasswordResetForm.vue"),
                meta: {
                    navigationAllowed: false,
                },
            },
            {
                path: "forgot-username",
                component: () => import("@/views/functional/UsernameReset.vue"),
                meta: {
                    navigationAllowed: false,
                },
            },
            {
                path: "reset-password",
                component: () => import("@/views/functional/PasswordReset.vue"),
                meta: {
                    navigationAllowed: false,
                },
                props: (route) => ({ token: route.query.token }),
            },
            {
                path: "account-confirm",
                component: () => import("@/views/user/login/AccountConfirm.vue"),
                meta: {
                    navigationAllowed: false,
                },
                props: (route) => ({ tokenID: route.query.tokenID }),
            },
        ],
        props: true,
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("@/views/registration/RegistrationView"),
    },
    {
        path: "/registerUser",
        name: "RegisterUser",
        component: () => import("@/views/registration/RegistrationUser"),
    },
    {
        path: "/registerMerchant",
        name: "RegisterMerchant",
        component: () => import("@/views/registration/RegistrationMerchant"),
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/user/Dashboard.vue"),
        meta: { restricted: true, navigationAllowed: true },
        children: [
            {
                path: "listing/:id/edit*",
                component: () => import("@/views/create/PostListing.vue"),
                name: "EditListing",
                props: true,
                children: [
                    {
                        path: "property",
                        name: "EditProperty",
                        components: {
                            create: () => import("@/views/property/RealEstateListingCreationForm.vue"),
                            preview: () => import("@/refactor/views/RealEstateListingView.vue"),
                        },
                    },
                    {
                        path: "merchant",
                        name: "EditService",
                        components: {
                            create: () => import("@/views/merchant/MerchantListingCreationForm.vue"),
                            preview: () => import("@/refactor/views/MerchantListingView.vue"),
                        },
                    },
                    {
                        path: "event",
                        name: "EditEvent",
                        components: {
                            create: () => import("@/views/event/EventListingCreationForm"),
                            preview: () => import("@/refactor/views/EventListingView.vue"),
                        },
                    },
                    {
                        path: "classified",
                        name: "EditClassified",
                        components: {
                            create: () => import("@/views/personal/PersonalListingCreationForm"),
                            preview: () => import("@/refactor/views/JobListingView.vue"),
                        },
                    },
                    {
                        path: "item",
                        name: "EditItem",
                        components: {
                            create: () => import("@/views/item/ItemListingCreationForm.vue"),
                            preview: () => import("@/refactor/views/ItemListingView.vue"),
                        },
                    },
                    {
                        path: "city_service",
                        name: "EditCityService",
                        components: {
                            create: () => import("@/views/city/CityServiceCreationForm"),
                            preview: () => import("@/refactor/views/CityServiceListingView.vue"),
                        },
                    },
                ],
            },
            {
                path: "profile",
                component: () => import("@/views/ProfileView.vue"),
                name: "Profile",
            },
            {
                path: "stripe",
                component: () => import("@/views/Stripe/StripeView.vue"),
                name: "Stripe",
            },
            {
                path: "payment",
                component: () => import("@/views/dashboard/Payment.vue"),
                name: "Payment",
            },
            {
                path: "manager-account",
                component: () => import("@/views/dashboard/ManagerAccount/ManagerAccount.vue"),
                name: "ManagerAccount",
            },
            {
                path: "manager-invitation",
                component: () => import("@/views/dashboard/ManagerInvitation.vue"),
                name: "ManagerInvitation",
            },
            {
                path: "security",
                component: () => import("@/views/dashboard/Security/Security.vue"),
                name: "Security",
            },
            {
                path: "file-management",
                component: () => import("@/views/storage/FileManagement.vue"),
                name: "FileManagement",
                props: true,
            },
            {
                path: "storage-checkout",
                component: () => import("@/views/storage/StorageCheckout.vue"),
                name: "storageCheckout",
                props: true,
            },
            {
                path: "receipt",
                component: () => import("@/views/Subscriptions/ReceiptView/ReceiptView.vue"),
                name: "ReceiptView",
                props: true,
            },
            {
                path: "listings",
                component: () => import("@/views/user/UserListingView.vue"),
                name: "Listings",
            },
            {
                path: "listing/post*",
                component: () => import("@/views/create/PostListing.vue"),
                name: "posting",
                props: true,
                children: [
                    {
                        path: "property",
                        name: "CreateProperty",
                        components: {
                            create: () => import("@/views/property/RealEstateListingCreationForm"),
                            preview: () => import("@/refactor/views/RealEstateListingView.vue"),
                        },
                    },
                    {
                        path: "merchant",
                        name: "CreateService",
                        components: {
                            create: () => import("@/views/merchant/MerchantListingCreationForm"),
                            preview: () => import("@/refactor/views/MerchantListingView.vue"),
                        },
                    },
                    {
                        path: "event",
                        name: "CreateEvent",
                        components: {
                            create: () => import("@/views/event/EventListingCreationForm"),
                            preview: () => import("@/refactor/views/EventListingView.vue"),
                        },
                    },
                    {
                        path: "classified",
                        name: "CreateClassified",
                        components: {
                            create: () => import("@/views/personal/PersonalListingCreationForm"),
                            preview: () => import("@/refactor/views/JobListingView.vue"),
                        },
                    },
                    {
                        path: "item",
                        name: "CreateItem",
                        components: {
                            create: () => import("@/views/item/ItemListingCreationForm"),
                            preview: () => import("@/refactor/views/ItemListingView.vue"),
                        },
                    },
                    {
                        path: "city_service",
                        name: "CreateCityService",
                        components: {
                            create: () => import("@/views/city/CityServiceCreationForm"),
                            preview: () => import("@/refactor/views/CityServiceListingView.vue"),
                        },
                    },
                ],
            },
            {
                path: "zones",
                component: () => import("@/components/geography/BillboardMap.vue"),
                name: "Zones",
            },
            {
                path: "subscription-map",
                component: () => import("@/views/dashboard/SubscriptionMap/SubscriptionMap.vue"),
                name: "subscriptionMap",
            },
            {
                path: "admin",
                component: AdministratorView,
                name: "Administration",
                beforeEnter(to, from, next) {
                    let allowed = checkAdministrator();

                    if (allowed) next();
                    else next("/");
                },
                children: [
                    {
                        path: "users",
                        component: () => import("@/views/administration/UserAdministrationView.vue"),
                        name: "Users",
                    },
                    {
                        path: "listings",
                        component: () => import("@/views/administration/ListingAdministrationView.vue"),
                        name: "AdminListings",
                    },
                    {
                        path: "zones",
                        component: () => import("@/views/administration/BillboardSettingsView"),
                        name: "AdminZones",
                    },
                    {
                        path: "homepage",
                        component: () => import("@/views/administration/HomepageAdministrationView.vue"),
                        name: "HomepageAdministrationView",
                        beforeEnter: loadSettingsAll,
                    },
                    {
                        path: "stripe",
                        component: () => import("@/views/administration/StripeAdministrationView"),
                        name: "StripeConfiguration",
                    },
                    {
                        path: "permissions",
                        component: () => import("@/views/administration/Permissions.vue"),
                        name: "Permissions",
                    },
                ],
            },

            {
                path: "notifications",
                name: "Notifications",
                component: () => import("@/views/notifications/NotificationsView/NotificationsView.vue"),
                meta: {
                    keepAlive: true,
                },

                children: [
                    {
                        path: "list",
                        name: "NotificationsList",
                        component: () => import("@/views/notifications/NotificationsList/NotificationsList.vue"),
                        meta: {
                            keepAlive: true,
                        },
                    },

                    {
                        path: "thread/:threadId",
                        name: "NotificationsThread",
                        component: () => import("@/views/notifications/NotificationsThread/NotificationsThread.vue"),
                        meta: {
                            keepAlive: true,
                        },
                    },
                    {
                        path: "compose",
                        name: "NotificationsCompose",
                        component: () => import("@/views/notifications/NotificationsCompose/NotificationsCompose.vue"),
                        meta: {
                            keepAlive: true,
                        },
                    },
                    {
                        path: "ignored",
                        name: "NotificationsIgnored",
                        component: () => import("@/views/notifications/NotificationsIgnored/NotificationsIgnored.vue"),
                        meta: {
                            keepAlive: true,
                        },
                    },

                    // need to create
                    // NotificationNav
                ],
            },
            {
                path: "alerts",
                name: "Alerts",
                component: () => import("@/views/alerts/AlertsView.vue"),
                meta: {
                    keepAlive: true,
                },
            },
        ],
    },
    {
        path: "/policy/:policyTitle",
        name: "policy",
        component: () => import("@/views/PolicyView.vue"),
    },
    {
        path: "/dashboard/marketplace/registration",
        component: () => import("@/views/marketplace/MarketplaceRegistration.vue"),
        name: "marketplaceRegistration",
    },
    {
        path: "/dashboard/marketplace/createShop",
        component: () => import("@/views/marketplace/CreateShop.vue"),
        name: "createShop",
        props: true,
    },
    {
        path: "/dashboard/marketplace/orders",
        component: () => import("@/views/marketplace/Orders.vue"),
        name: "orders",
        props: true,
    },
    {
        path: "/dashboard/marketplace/orders/order/:orderId",
        component: () => import("@/views/marketplace/OrderDetails.vue"),
        name: "orderDetails",
        props: true,
    },
    {
        path: "/dashboard/marketplace/shops/shop/:shopId/summary",
        component: () => import("@/views/marketplace/shop/ShopSummary.vue"),
        name: "shopSummary",
        props: true,
    },
    {
        path: "/dashboard/marketplace/shops/shop/:shopId/invoices",
        component: () => import("@/views/marketplace/shop/ShopInvoices.vue"),
        name: "ShopInvoices",
        props: true,
    },
    {
        path: "/dashboard/marketplace/shops/shop/:shopId/invoices/:invoiceId",
        component: () => import("@/views/marketplace/InvoiceDetails.vue"),
        name: "InvoiceDetails",
        props: true,
    },
    {
        path: "/dashboard/marketplace/shops/shop/:shopId/products",
        component: () => import("@/views/marketplace/shop/ShopProducts.vue"),
        name: "shopProducts",
        props: true,
    },
    {
        path: "/dashboard/marketplace/shops/shop/:shopId/setting",
        component: () => import("@/views/marketplace/shop/ShopSetting.vue"),
        name: "shopSetting",
        props: true,
    },
    {
        path: "/dashboard/marketplace/shops/shop/:shopId/setting/PostShippingRate",
        component: () => import("@/views/marketplace/PostShippingRate.vue"),
        name: "shippingRatePost",
        props: true,
    },
    {
        path: "/dashboard/marketplace/product/post",
        component: () => import("@/views/marketplace/PostProduct.vue"),
        name: "productPost",
        props: true,
    },
    {
        path: "/dashboard/marketplace/product/edit",
        component: () => import("@/views/marketplace/PostProduct.vue"),
        name: "productEdit",
        props: true,
    },
    {
        path: "/dashboard/marketplace/cart",
        component: () => import("@/views/marketplace/Cart.vue"),
        name: "Cart",
        props: true,
    },
    {
        path: "/dashboard/marketplace/checkoutSuccess",
        component: () => import("@/views/marketplace/CheckoutSuccess.vue"),
        name: "CheckoutSuccess",
        props: true,
    },
    {
        path: "/dashboard/groups/invite",
        component: () => import("@/views/dashboard/CityProfileIntroduction.vue"),
        meta: {
            navigationAllowed: false,
        },
        props: (route) => ({ token: route.query.token }),
    },
    {
        path: "/merchantIntroduction",
        name: "merchantIntroduction",
        component: () => import("@/views/dashboard/MerchantIntroduction.vue"),
        props: true,
    },
    {
        path: "/realtorIntroduction",
        name: "RealtorIntroduction",
        component: () => import("@/views/dashboard/RealtorIntroduction.vue"),
        props: true,
    },
    {
        name: "BrokerageIntroduction",
        path: "/brokerageIntroduction",
        component: () => import("@/views/dashboard/BrokerageIntroduction.vue"),
        props: true,
    },
    {
        name: "CityProfileIntroduction",
        path: "/cityProfileIntroduction",
        component: () => import("@/views/dashboard/CityProfileIntroduction.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    //scroll to top
    if (to.path !== from.path) {
        window.scrollTo({
            top: 0,
            // behavior: "smooth",
        });
    }

    let restricted = to.matched.some((m) => m.meta.restricted);
    if (restricted) {
        if (store.getters["user/isFirstLoad"]) {
            store.dispatch("user/getUser").then((loggedIn) => {
                if (loggedIn) {
                    next();
                } else {
                    return next("/dashboard/login");
                }
            });
        } else if (store.getters["user/getLoggedIn"]) {
            next();
        } else {
            return next("/dashboard/login");
        }
    } else {
        next();
    }
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

// error handler
const onError = (e) => {
    // avoid NavigationDuplicated
    if (e.name !== "NavigationDuplicated" && e.message.indexOf("Avoided redundant navigation") < 0) throw e;
};

// keep original function
const _replace = router.__proto__.replace;
// then override it
router.__proto__.replace = function replace(...args) {
    try {
        const op = _replace.call(this, ...args);
        if (op instanceof Promise) {
            op.catch(onError);
        }
        return op;
    } catch (e) {
        onError(e);
    }
};

sync(store, router);

export default router;
