<template>
	<div class="m-video" :class="{ 'u-video-hover': !hidden }">
		<video ref="veoRef" class="u-video"
			:style="{ ...styles, 'object-fit': zoom, 'width': '100%', 'height': height, }" :poster="veoPoster"
			:autoplay="autoplay" :controls="!isFirstPlay && controls" :loop="loop" :muted="autoplay || muted"
			:preload="preload" crossorigin="anonymous" @loadeddata="poster ? () => false : getPoster()"
			@pause="showPlay ? onPause() : () => false" @playing="showPlay ? onPlaying() : () => false"
			@click.prevent.once="onPlay" playsinline webkit-playsinline v-bind="$attrs">
			<source :src="`${$helpers.getFileUrl(file.path)}#t=0.1`" type="video/mp4" />
			Your browser does not support the video tag.
		</video>
		<svg v-show="isFirstPlay || (!mobile && showPlay)" class="u-play" :class="{ 'hidden': hidden }"
			:style="`width: ${playWidth}px; height: ${playWidth}px;`" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="8" fill="rgba(0, 0, 0, 0.5)" />
			<polygon points="9.5,8.5 15.5,12 9.5,15.5" fill="#ffeb3b" />
		</svg>
		<v-card flat v-if="showLogo && showPlay" :class="{ 'hidden': hidden }" class="watermark">
			<v-row class="ma-0" justify="end">
				<img :src="watermarkSrc" alt="Watermark" style="height: 100%; width: 100%;">
			</v-row>
			<v-row class="ma-0" justify="end">
				<v-menu max-width="280px" left allow-overflow @click.native.stop.prevent>
					<template #activator="{ on, attrs }">
						<v-btn @click.native.stop.prevent icon x-large color="lightblue" v-on="on" v-bind="attrs">
							<v-icon color="white">mdi-share</v-icon>
						</v-btn>
					</template>
					<v-card color="" outlined>
						<v-card-title class="py-0 text-center" style="text-align: center; width: 100%;">
							<div style="width: 100%; text-align: center;">
								Share This Listing
							</div>
						</v-card-title>
						<v-card-actions>
							<ShareNetwork :title="shareMessage(file)" url="" network="Facebook"
								:description="formattedDescription(file)">
								<v-icon x-large color="#3b5998">mdi-facebook</v-icon>
							</ShareNetwork>
							<ShareNetwork :title="shareMessage(file)" url="" network="Twitter"
								:description="formattedDescription(file)">
								<v-icon x-large color="#1DA1F2">mdi-twitter</v-icon>
							</ShareNetwork>
							<ShareNetwork :title="shareMessage(file)" url="" network="LinkedIn"
								:description="formattedDescription(file)">
								<v-icon x-large color="#0072b1">mdi-linkedin</v-icon>
							</ShareNetwork>
							<ShareNetwork :title="shareMessage(file)" url="" network="Email"
								:description="formattedDescription(file)">
								<v-icon x-large color="lightgrey">mail</v-icon>
							</ShareNetwork>
							<ShareNetwork title="" url="" network="SMS" :description="formattedDescription(file)">
								<v-icon x-large color="#1565C0">sms</v-icon>
							</ShareNetwork>
						</v-card-actions>
					</v-card>
				</v-menu>
			</v-row>
		</v-card>
	</div>
</template>

<script>

export default {
	name: "BrebVideo",
	components: {

	},
	mixins: [],
	props: {
		file: {
			type: Object,
			required: true,
		},
		height: {
			type: [String],
		},
		width: {
			type: [String],
			default: '100%'
		},
		styles: {
			type: Object,
			default: () => { },
		},
		poster: {
			type: String,
			default: ''
		},
		posterSecond: {
			type: Number,
			default: 0.1
		},
		autoplay: {
			type: Boolean,
			default: false
		},
		loop: {
			type: Boolean,
			default: false
		},
		muted: {
			type: Boolean,
			default: false
		},
		preload: {
			type: String,
			default: 'auto'
		},
		controls: {
			type: Boolean,
			default: true,
		},
		showPlay: {
			type: Boolean,
			default: true
		},
		playWidth: {
			type: Number,
			default: 96
		},
		zoom: {
			type: String,
			default: 'contain'
		},
		showLogo: {
			type: Boolean,
			default: true,
		},
		sharelink: {
			type: String,
			default: () => '',
		},
	},
	data: function () {
		return {
			veoPoster: this.poster,
			isFirstPlay: true,
			hidden: false,
			watermarkSrc: require("@/assets/breb.rect.logo.new-w200.png"),
		}
	},
	computed: {
		mobile() {
			return this.$vuetify.breakpoint.mobile
		},
		cellphone() {
			return this.$vuetify.breakpoint.xs
		},
	},
	watch: {

	},
	methods: {
		getPoster() {
			this.$refs.veoRef.currentTime = this.posterSecond

			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')

			canvas.width = this.$refs.veoRef.videoWidth
			canvas.height = this.$refs.veoRef.videoHeight
			ctx.drawImage(this.$refs.veoRef, 0, 0, canvas.width, canvas.height)

			this.veoPoster = canvas.toDataURL('image/png')
		},
		onPlay() {
			if (!this.controls) {
				return;
			}
			if (this.isFirstPlay) {
				this.$refs.veoRef.play()
				this.isFirstPlay = false;
				return;
			}
			if (!this.$refs.veoRef.paused) {
				this.$refs.veoRef.pause()
			} else {
				this.$refs.veoRef.play()
			}
		},
		onPause() {
			this.hidden = false
		},
		onPlaying() {
			this.isFirstPlay = false;
			this.hidden = true
		},
		shareMessage: function (file) {
			if (!file) {
				return "";
			}
			return "Wow... Check out this video on MyBREB.com"
		},
		formattedDescription(file) {
			if (this.sharelink) {
				return `Hey, 

Check out this amazing video I found on MyBREB.com.

${this.sharelink}`;
			} else {
				return `Hey, 

Check out this amazing video I found on MyBREB.com.

${window.location.origin}${this.$route.path}?videoShare=1`;
			}
		},
	},
	created() {

	},
	beforeMount() {

	},
	mounted() {
		if (this.autoplay) {
			this.hidden = true
			this.isFirstPlay = false
		}
	},
	beforeDestroy() {

	},

}
</script>

<style lang="less" scoped>
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.m-video {
	display: inline-block;
	position: relative;
	background-color: #d3d3d3;
	cursor: pointer;
	width: 100%;
	height: 100%;

	.u-video {
		display: inline-block;
		background-color: #d3d3d3;
		width: 100%;
		height: 100%;
		vertical-align: bottom;
	}

	.u-play {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		fill: none;
		color: #FFF;
		pointer-events: none;
		opacity: 0.9;
		transition: opacity .3s;

		path {
			stroke: #FFF;
		}
	}

	.hidden {
		opacity: 0;
	}

	.watermark {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 100px;
		height: 37px;
		padding: 0px;
		margin: 0px;
	}

	// .watermark img {
	// 	width: 100px;
	// 	height: 100%;
	// }
}

.u-video-hover {
	&:hover {
		.u-play {
			opacity: 1;
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
@import 'node_modules/vuetify/src/styles/settings/variables';
@import "src/globals";
@media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {}
</style>