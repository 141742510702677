const module = {
    state: function () {
        let isLive = window.location.origin === "https://www.bestrealestatebuy.com";
        return {
            serviceTypes: [
                { value: "APPLIANCES_AND_ELECTRONICS", text: "Appliances & Electronics" },
                { value: "BUILDERS_AND_CONTRACTORS", text: "Builders & Contractors" },
                { value: "CONCRETE_PAVING_STONE", text: "Concrete Paving & Stone" },
                { value: "FLOORING_AND_INSTALLATIONS", text: "Flooring & Installations" },
                { value: "GENERAL_HELP", text: "Handyman Work & Repairs" },
                { value: "HEATING_AND_AIR_CONDITIONING", text: "Heating & Air Conditioning" },
                { value: "HOME_DECOR", text: "Home Furnishings & Decor" },
                { value: "INSURANCE_AND_RESTORATIONS", text: "Insurance & Restorations" },
                { value: "KITCHEN_AND_BATH", text: "Kitchens & Bathrooms" },
                { value: "LAWN_FENCE_DECK", text: "Lawns, Fences & Decks" },
                { value: "MATERIALS_AND_SUPPLIES", text: "Materials & Supplies" },
                { value: "LEGAL_SERVICES", text: "Mortgages & Legal Services" },
                { value: "POOLS_AND_HOTTUBS", text: "Plumbing, Pools & Spas" },
                { value: "PROFESSIONAL_CLEANING_SERVICES", text: "Professional Cleaning Services" },
                { value: "ROOFING", text: "Roofing, Siding & Troughs" },
                { value: "PROFESSIONAL_SERVICES", text: "Skilled Professional Services" },
                { value: "WINDOWS_AND_GLASS", text: "Window, Doors, Skylights" },
            ],
            eventTypes: [
                { value: "OPEN_HOUSE", text: "Open Houses" },
                { value: "GARAGE_SALE", text: "Garage Sales" },
                { value: "FORECLOSURES", text: "Foreclosures" },
                { value: "ESTATE_SALE", text: "Estate Sales" },
                { value: "PROPERTY_AUCTIONS", text: "Property Auctions" },
            ],
            itemTypes: [
                { value: "CLASSIFIED", text: "New & Used Household Items" },
                { value: "CLEAROUT", text: `Inventory Clearance Sale` },
                // { value: "EXCLUSIVE_ONLINE", text: `Factory Direct Blowouts ${isLive?'<span style="color: red;">(Coming Soon)</span>':''}`, disabled: isLive, },
                { value: "EXCLUSIVE_ONLINE", text: `Factory Direct Blowouts` },
            ],
            personalTypes: [
                { value: "HELP_WANTED", text: "Help Wanted and Trade Jobs" },
                { value: "BUSINESS_OPPORTUNITY", text: "Business Opportunities" },
                { value: "CAPITAL_NEEDED", text: "Capital Needed/Available" },
            ],
            businessOpportunities: [
                { text: "Real Estate", value: "REAL_ESTATE" },
                { text: "Retail/Storefront", value: "RETAIL_STOREFRONT" },
                { text: "Office/Admin", value: "OFFICE_ADMINISTRATION" },
                { text: "Hospitality", value: "HOSPITALITY" },
                { text: "Marketing/Sales", value: "MARKETING_SALES" },
                { text: "Technology", value: "TECHNOLOGY" },
                { text: "Industrial", value: "INDUSTRIAL" },
                { text: "Agriculture/Farm", value: "AGRICULTURE_FARM" },
                { text: "Manufacturing", value: "MANUFACTURING" },
                { text: "Commercial", value: "COMMERCIAL" },
                { text: "Other", value: "OTHER" },
            ],
            personalJobTypes: [
                { text: "General Help", value: "GENERAL_HELP" },
                { text: "Administration and Management", value: "ADMIN_MANAGEMENT" },
                { text: "Drivers and Operators", value: "DRIVERS_OPERATORS" },
                { text: "Hospitality", value: "HOSPITALITY" },
                { text: "Retail and Office Workers", value: "RETAIL_OFFICE" },
                { text: "Sales and Marketing", value: "SALES_MARKETING" },
                { text: "Skilled Trades", value: "SKILLED_TRADES" },
                { text: "Other", value: "OTHER" },
            ],
            propertyTypes: [
                { value: "RESIDENTIAL", text: "Residential" },
                { value: "COMMERCIAL", text: "Commercial" },
                { value: "MIXED_USE", text: "Mixed Use" },
                { value: "INDUSTRIAL", text: "Industrial" },
                { value: "AGRICULTURAL", text: "Farm/Agricultural" },
                { value: "NEW_DEVELOPMENT", text: "Pre/New Construction" },
                { value: "LAND_LOTS", text: "Vacant Land/Lot" },
                { value: "VACATION", text: "Vacation Home/Cottage" },
                { value: "STUDENT_HOUSING", text: "Student Housing/Rentals" },
                { value: "RETIREMENT", text: "Retirement/Long Term Care" },
            ],
            cityServiceTypes: [{ value: "CITY_DIRECTORY", text: "City Profile/Events/Services" }],
            directories: [
                //City Hall 22
                { type: "CITY_HALL", value: "CITY_HALL", text: "City Hall" },
                { type: "CITY_HALL", value: "MAYOR_OFFICE", text: "Mayor’s Office" },
                { type: "CITY_HALL", value: "CHIEF_ADMINISTRATOR", text: "Chief Administrator" },
                { type: "CITY_HALL", value: "CLERK_PROPERTY_CLAIM", text: "Clerk/Property Claim" },
                { type: "CITY_HALL", value: "BUILDING_PERMITS", text: "Building Permits" },
                { type: "CITY_HALL", value: "BUILDING_PLANNING", text: "Building Planning" },
                { type: "CITY_HALL", value: "BUILDING_INSPECTIONS", text: "Building Inspections" },
                { type: "CITY_HALL", value: "BUILDING_DEPT", text: "Building Department" },
                { type: "CITY_HALL", value: "BUSINESS_DEVELOPMENT", text: "Business Development" },
                { type: "CITY_HALL", value: "BUSINESS_DEPT", text: "Business Department" },
                { type: "CITY_HALL", value: "ENVIRONMENTAL_SERVICES", text: "Environmental Studies" },
                { type: "CITY_HALL", value: "PROPERTY_ASSESSMENT", text: "Property Assessment/Taxes" },
                { type: "CITY_HALL", value: "PROPERTY_INVESTIGATION", text: "Property Investigation" },
                { type: "CITY_HALL", value: "PLANNING_DEPT", text: "Planning Department" },
                { type: "CITY_HALL", value: "FIRE_CODE_ENFORCEMENT", text: "Fire Code Enforcement" },
                { type: "CITY_HALL", value: "ROADS_TRANSPORTATION", text: "Roads/Transportation" },
                { type: "CITY_HALL", value: "TREE_SERVICES", text: "Tree Removal Programs" },
                { type: "CITY_HALL", value: "SEWAGE_DEPT", text: "Sewers/Sewage Department" },
                { type: "CITY_HALL", value: "URBAN_DRAINAGE", text: "Urban Drainage" },
                { type: "CITY_HALL", value: "WATER_DEPT", text: "Water Services" },
                { type: "CITY_HALL", value: "WASTE_AMANAGEMENT", text: "Waste Management" },
                // { type:"CITY_HALL", value: "TAX_DEPARTMENT", text: "Tax Department" },

                //911 Emergency 8
                { type: "EMERGENCY", value: "POLICE", text: "Police" },
                { type: "EMERGENCY", value: "FIRE", text: "Fire" },
                { type: "EMERGENCY", value: "HOSPITAL", text: "Hospital" },
                { type: "EMERGENCY", value: "AMBULANCE", text: "Ambulance" },
                { type: "EMERGENCY", value: "POISON", text: "Poison" },
                { type: "EMERGENCY", value: "HUMANE_SOCIETY", text: "Humane Society" },
                { type: "EMERGENCY", value: "LEGAL_AID", text: "Legal Aids" },
                { type: "EMERGENCY", value: "WALK_IN_CLINIC", text: "Walk In Clinics" },

                //Utilities 7
                { type: "UTILITIES", value: "GAS", text: "Gas" },
                { type: "UTILITIES", value: "HYDRO", text: "Hydro" },
                { type: "UTILITIES", value: "CABLE", text: "Cable" },
                { type: "UTILITIES", value: "WATER", text: "Water" },
                { type: "UTILITIES", value: "WASTE", text: "Waste" },
                { type: "UTILITIES", value: "PHONE", text: "Phone" },
                { type: "UTILITIES", value: "SEWERS", text: "Sewers" },

                //Recycling 7
                // { type:"RECYCLING", value: "RECYCLING", text: "Recycling" },
                { type: "RECYCLING", value: "ELECTRONICS", text: "Electonic Devices" },
                { type: "RECYCLING", value: "PAINTS", text: "Paints/Chemicals" },
                { type: "RECYCLING", value: "TIRES", text: "Rubber/Tires" },
                { type: "RECYCLING", value: "PLASTICS", text: "Plastics/Cardboard" },
                // { type:"RECYCLING", value: "CARDBOARD", text: "Cardboard" },
                { type: "RECYCLING", value: "OTHER", text: "Other" },
            ],
            directoryType: [
                { value: "CITY_HALL", text: "City Hall" },
                { value: "EMERGENCY", text: "911 Emergency" },
                { value: "UTILITIES", text: "Utilities" },
                { value: "RECYCLING", text: "Recycling" },
            ],
            realtorTypes: [{ value: "REALTOR", text: "Realtors/Brokers" }],
            buildingTypes: {
                RESIDENTIAL: [
                    { value: "APARTMENT", text: "Apartment", valid: () => true },
                    { value: "CONDOMINIUM", text: "Condominium", valid: () => true },
                    { value: "BUNGALOW", text: "Bungalow", valid: () => true },
                    { value: "RANCH", text: "Ranch Style", valid: () => true },
                    { value: "MULTI_FAMILY", text: "Multi-Family Home", valid: () => true },
                    { value: "HOUSES", text: "House", valid: () => true },
                    { value: "TWO_STORY", text: "Two Story Home", valid: () => true },
                    { value: "THREE_STORY", text: "Three Story Home", valid: () => true },
                    { value: "DUPLEX", text: "Duplex House", valid: () => true },
                    { value: "TRIPLEX", text: "Triplex House", valid: () => true },
                    { value: "FOURPLEX", text: "Fourplex House", valid: () => true },
                    { value: "SEMI", text: "Semi-Detached Home", valid: () => true },
                    { value: "MOBILE_HOME", text: "Mobile RV Park Home", valid: () => true },
                    { value: "TOWNHOUSE", text: "Townhouse", valid: () => true },
                    { value: "OTHER", text: "Other Type", valid: () => true },
                ],
                COMMERCIAL: [
                    { value: "BUSINESS", text: "Business", valid: () => true },
                    { value: "HOSPITALITY", text: "Hospitality", valid: () => true },
                    { value: "INSTITUTIONAL", text: "Institutional", valid: () => true },
                    { value: "NEW_DEVELOPMENT", text: "New Development", valid: () => true },
                    { value: "OFFICE", text: "Office", valid: () => true },
                    { value: "DUPLEX", text: "Duplex House", valid: () => true },
                    { value: "TRIPLEX", text: "Triplex House", valid: () => true },
                    { value: "FOURPLEX", text: "Fourplex House", valid: () => true },
                    { value: "PARKING", text: "Parking Lot", valid: () => true },
                    { value: "RETAIL", text: "Retail Space", valid: () => true },
                    { value: "OTHER", text: "Other Type", valid: () => true },
                ],
                INDUSTRIAL: [
                    { value: "PRODUCTION", text: "Production", valid: () => true },
                    { value: "STORAGE", text: "Storage Facility", valid: () => true },
                ],
                AGRICULTURAL: [
                    { value: "HOUSES", text: "House", valid: () => true },
                    { value: "BARN", text: "Barn", valid: () => true },
                    { value: "STORAGE", text: "Storage Facility", valid: () => true },
                    { value: "PRODUCTION", text: "Meat/Dairy Production", valid: () => true },
                    { value: "RANCH", text: "Ranch Style", valid: () => true },
                    { value: "OTHER", text: "Other Type", valid: () => true },
                ],
                MIXED_USE: [
                    { value: "APARTMENT", text: "Apartment", valid: () => true },
                    { value: "CONDOMINIUM", text: "Condominium", valid: () => true },
                    { value: "BUNGALOW", text: "Bungalow", valid: () => true },
                    { value: "MULTI_FAMILY", text: "Multi-Family Home", valid: () => true },
                    { value: "HOUSES", text: "House", valid: () => true },
                    { value: "TOWNHOUSE", text: "Townhouse", valid: () => true },
                    { value: "BUSINESS", text: "Business", valid: () => true },
                    { value: "INSTITUTIONAL", text: "Institutional", valid: () => true },
                    { value: "OFFICE", text: "Office", valid: () => true },
                    { value: "OTHER", text: "Other Type", valid: () => true },
                ],
                LAND_LOTS: [{ value: "HOUSES", text: "House and Lot", valid: () => true }],
                VACATION: [
                    { value: "COTTAGE", text: "Cottage", valid: () => true },
                    { value: "AIRBNB", text: "AirBnB", valid: () => true },
                    { value: "MOBILE_HOME", text: "Mobile RV Park Home", valid: () => true },
                    { value: "SEMI", text: "Semi-Detached Home", valid: () => true },
                    { value: "TOWNHOUSE", text: "Townhouse", valid: () => true },
                    { value: "HOUSES", text: "House", valid: () => true },
                    { value: "TWO_STORY", text: "Two Story Home" },
                    { value: "THREE_STORY", text: "Three Story Home" },
                    { value: "APARTMENT", text: "Apartment", valid: () => true },
                    { value: "CONDOMINIUM", text: "Condominium", valid: () => true },
                    { value: "CAMPGROUND", text: "Campground", valid: () => true },
                    { value: "RANCH", text: "Ranch Style", valid: () => true },
                    { value: "OTHER", text: "Other Type", valid: () => true },
                ],
                STUDENT_HOUSING: [
                    { value: "APARTMENT", text: "Apartment", valid: () => true },
                    { value: "CONDOMINIUM", text: "Condominium", valid: () => true },
                    { value: "BUNGALOW", text: "Bungalow", valid: () => true },
                    { value: "MULTI_FAMILY", text: "Multi-Family Home", valid: () => true },
                    { value: "HOUSES", text: "House", valid: () => true },
                    { value: "SEMI", text: "Semi-Detached Home", valid: () => true },
                    { value: "TOWNHOUSE", text: "Townhouse", valid: () => true },
                ],
                RETIREMENT: [
                    { value: "APARTMENT", text: "Apartment", valid: () => true },
                    { value: "CONDOMINIUM", text: "Condominium", valid: () => true },
                    { value: "MULTI_FAMILY", text: "Multi-Family Home", valid: () => true },
                    { value: "RETIREMENT_HOMES", text: "Nursing/Retirement Home", valid: () => true },
                    { value: "MOBILE_HOME", text: "Mobile RV Park Home", valid: () => true },
                    { value: "OTHER", text: "Other Type", valid: () => true },
                ],
                NEW_DEVELOPMENT: [
                    { value: "HOUSES", text: "House and Lot", valid: () => true },
                    { value: "BUSINESS", text: "Business Plaza", valid: () => true },
                    { value: "CONDOMINIUM", text: "Condominium", valid: () => true },
                    { value: "TOWNHOUSE", text: "Townhouse", valid: () => true },
                    { value: "OTHER", text: "Other Type", valid: () => true },
                ],
            },
            saleTypes: [
                { value: "SALE", text: "For Sale" },
                { value: "RENT", text: "For Rent" },
                { value: "LEASE", text: "For Lease" },
                // { value: "LEASE_TO_OWN", text: "Lease to Own", },
            ],
            featureTypes: [
                { value: "BUILDING", text: "Building" },
                { value: "EXTERIOR", text: "Exterior" },
                { value: "HEATING_AND_COOLING", text: "Heating and Cooling" },
                { value: "INTERIOR", text: "Interior" },
                { value: "OTHER", text: "Other" },
                { value: "PARKING", text: "Parking" },
                { value: "SIZE", text: "Size" },
                { value: "UTILITIES", text: "Utilities" },
            ],
            roomTypes: [
                { value: "BEDROOM_MASTER", text: "Master bedroom" },
                { value: "BEDROOM", text: "Bedroom" },
                { value: "BATHROOM_FULL", text: "Full bathroom" },
                { value: "BATHROOM_HALF", text: "Half-bathroom" },
                { value: "DINING_ROOM", text: "Dining room" },
                { value: "LIVING_ROOM", text: "Living room" },
                { value: "FAMILY_ROOM", text: "Family room" },
                { value: "LAUNDRY_ROOM", text: "Laundry room" },
                { value: "KITCHEN", text: "Kitchen" },
                { value: "OFFICE", text: "Office Space" },
                { value: "FOYER", text: "Foyer" },
                { value: "BASEMENT", text: "Basement" },
                { value: "CRAWL_SPACE", text: "Crawl Space" },
                { value: "DEN", text: "Den" },
            ],
            paymentPeriods: [
                { text: "Fixed Price", value: "ONCE" },
                { text: "Per Hour", value: "HOURLY" },
                { text: "Per Day", value: "DAILY" },
                { text: "Per Month", value: "MONTHLY" },
                { text: "Per Week", value: "WEEKLY" },
                { text: "Biweekly", value: "BIWEEKLY" },
                { text: "Every 6 months", value: "SEMIANNUAL" },
                { text: "Annually", value: "ANNUAL" },
            ],
            paymentsTypes: [
                { value: "MASTERCARD", text: "MASTERCARD" },
                { value: "VISA", text: "VISA" },
                { value: "CHEQUE", text: "CHEQUE" },
                { value: "AMERICAN_EXPRESS", text: "AMEX" },
                { value: "CASH", text: "CASH" },
            ],
            employmentTerms: [
                { text: "Seasonal", value: "SEASONAL" },
                { text: "Full Time", value: "FULL_TIME" },
                { text: "Part Time", value: "PART_TIME" },
                { text: "Contractual", value: "CONTRACT" },
                { text: "Temporary", value: "TEMPORARY" },
            ],
            mainCategoryTypes: [
                { text: "Supplies & Services", value: "merchant" },
                { text: "Marketplace", value: "item" },
                { text: "Real Estate & Rental Listings", value: "property" },
                { text: "Local Real Estate Events", value: "event" },
                { text: "MyCity & Municipal Directory", value: "city_service" },
                { text: "Top Real Estate Reps/Brokers", value: "realtor" },
                { text: "Work/Jobs & Opportunities", value: "classified" },
            ],
            itemClasses: [
                { value: "APPLIANCES", text: "Appliances" },
                { value: "ELECTRONICS", text: "Electronics" },
                { value: "FURNITURE", text: "Furniture" },
                { value: "HOME_DECOR", text: "Home Decor" },
                { value: "INDOOR", text: "Indoor" },
                { value: "OUTDOOR", text: "Outdoor" },
                { value: "TOOLS", text: "Tools" },
                { value: "LAWN_EQUIPMENT", text: "Lawn Equipment" },
                { value: "MISCELLANEOUS", text: "Miscellaneous Items" },
            ],
            realtorPositions: [
                { value: "REPRESENTATIVE", text: "Representative" },
                { value: "BROKER", text: "Broker" },
            ],
            productTypes: [
                // { text: "Classified", value: "CLASSIFIED" },
                { value: "CLEAROUT", text: "Inventory Clearance Sale" },
                { value: "EXCLUSIVE_ONLINE", text: "Factory Direct Blowouts" },
            ],
            productCategories: [
                { value: "APPLIANCES", text: "Appliances" },
                { value: "BUILDING_MATERIALS", text: "Building Materials" },
                { value: "DOORS_WINDOWS", text: "Doors Windows" },
                { value: "ELECTRONICS", text: "Electronics" },
                { value: "FLOORING", text: "Flooring" },
                { value: "FURNITURE", text: "Furniture" },
                { value: "HEATING_AIR", text: "Heating Air" },
                { value: "HOME_DECOR", text: "Home Decoration" },
                { value: "HOME_SUPPLIES", text: "Home Supplies" },
                { value: "KITCHEN_BATH", text: "Kitchen Bath" },
                { value: "LAWN_GARDEN", text: "Lawn Garden" },
                { value: "LUMBER", text: "Lumber" },
                { value: "OTHER", text: "Miscellaneous" },
                { value: "ROOFING_SIDING", text: "Roofing Siding" },
                { value: "TOOLS_EQUIPMENT", text: "Tools Equipment" },
            ],
            productConditions: [
                { text: "New", value: "NEW" },
                { text: "Used", value: "USED" },
                { text: "Lightly Used", value: "LIGHTLY_USED" },
                { text: "Heavily Used", value: "HEAVILY_USED" },
                { text: "As Is", value: "AS_IS" },
            ],
            shippingSupports: [
                {
                    text: "Extra Shipping Fee",
                    value: "FIXED_RATE",
                    description: "Extra Shipping Fee also supports pick up in store.",
                },
                {
                    text: "Free Shipping",
                    value: "INCLUDED",
                    description: "Free Shipping also supports pick up in store.",
                },
                {
                    text: "Pick Up Only",
                    value: "PICKUP_ONLY",
                    description: "Pick Up Only supports pick up in store only.",
                },
                {
                    text: "Contact Store",
                    value: "CONTACT_STORE",
                    description: "Needs to contact store for shipping detail.",
                },
            ],
            orderStatus: [
                { text: "Cancelled", value: "CANCELLED" },
                { text: "Failed", value: "FAILED" },
                { text: "Funds Pending", value: "FUNDS_PENDING" },
                { text: "Open", value: "OPEN" },
                { text: "Paid", value: "PAID" },
            ],
            articleTypes: [
                { text: "BREB News Article", value: "NEWS" },
                { text: "Important Information", value: "IMPORTANT_INFORMATION" },
                { text: "Breaking News", value: "BREAKING_NEWS" },
                { text: "Press Release", value: "PRESS_RELEASE" },
                { text: "Mortgages & Rates", value: "MORTGAGES_RATES" },
                { text: "Business Finance", value: "FINANCE" },
                { text: "Real Estate News", value: "REAL_ESTATE_NEWS" },
                { text: "Marketing Media", value: "MARKETING" },
                { text: "Advertisement", value: "ADVERTISING" },
                { text: "Other", value: "OTHER" },
                { text: "No Type", value: null },
            ],
            permissions: [
                { text: "Review", value: "read" },
                { text: "Edit and Manage", value: "write" },
                { text: "Creator", value: "create" },
                { text: "Delete", value: "delete" },
                { text: "Administration", value: "administration" },
            ],
            groups: [
                { text: "Administrators", value: "administrators" },
                { text: "Merchants", value: "merchants" },
                { text: "artners", value: "partners" },
                { text: "City Administrators", value: "city_administrators" },
                { text: "Realtors", value: "realtors" },
            ],
            neighborhoodArray: [
                "Parks",
                "Dog Parks",
                "Shopping",
                "Banks",
                "Churches",
                "Hospitals",
                "Schools",
                "Bus Routes",
                "Highway Access",
                "Health Practitioners",
                "Fitness Centres",
            ],
        };
    },
    getters: {
        getEmploymentTerms: (state) => state.employmentTerms,
        getPersonJobTypes: (state) => {
            return state.personalJobTypes;
        },
        getBusinessOpportunities: (state) => {
            return state.businessOpportunities;
        },
        getSubscriptionTiers: (state) => {
            return state.subscriptionTiers;
        },
        getPersonalTypes: (state) => {
            return state.personalTypes;
        },
        getPropertyTypes: (state) => {
            return state.propertyTypes;
        },
        getCityServiceTypes: (state) => {
            return state.cityServiceTypes;
        },
        getDirectories: (state) => {
            return state.directories;
        },
        getDirectoryTypes: (state) => {
            return state.directoryType;
        },
        getRealtorTypes: (state) => {
            return state.realtorTypes;
        },
        getBuildingTypes: (state) => {
            return state.buildingTypes;
        },
        getSaleTypes: (state) => {
            return state.saleTypes;
        },
        getFeatureTypes: (state) => {
            return state.featureTypes;
        },
        getRoomTypes: (state) => {
            return state.roomTypes;
        },
        getPaymentPeriods: (state) => {
            return state.paymentPeriods;
        },
        getItemTypes: (state) => {
            return state.itemTypes;
        },
        getEventTypes: (state) => {
            return state.eventTypes;
        },
        getServiceTypes: (state) => {
            return state.serviceTypes;
        },
        getPaymentsTypes: (state) => {
            return state.paymentsTypes;
        },
        getMaintypes: (state) => {
            return state.mainCategoryTypes;
        },
        getItemClasses: (state) => state.itemClasses,
        combined: function () {
            return [].concat(
                this.state.property,
                this.state.events,
                this.state.merchants,
                this.state.jobs,
                this.state.items
            );
        },
        getType: function (state) {
            return function (type) {
                switch (type) {
                    case "realtor":
                        return state.realtorTypes;
                    case "property":
                        return state.propertyTypes;
                    case "merchant":
                        return state.serviceTypes;
                    case "classified":
                        return state.personalTypes;
                    case "event":
                        return state.eventTypes;
                    case "item":
                        return state.itemTypes;
                    case "city_service":
                        return state.cityServiceTypes;
                }
            };
        },
        getRealtorPositions: (state) => {
            return state.realtorPositions;
        },
        getProductTypes: (state) => {
            return state.productTypes;
        },
        getProductCategories: (state) => {
            return state.productCategories;
        },
        getProductConditions: (state) => {
            return state.productConditions;
        },
        getShippingSupports: (state) => {
            return state.shippingSupports;
        },
        getOrderStatus: (state) => {
            return state.orderStatus;
        },
        getArticleTypes: (state) => {
            return state.articleTypes;
        },
        getPermissions: (state) => {
            return state.permissions;
        },
        getGroups: (state) => {
            return state.groups;
        },
        getNeighborhoodArray: (state) => {
            return state.neighborhoodArray;
        },
    },
    actions: {},
    mutations: {},
};

export default module;
