import axios from "axios";

export default {
    namespaced: true,
    state: {
        stats: null,
        storagePackages: [
            {
                name: "10GB",
                storage: "10GB",
                listingLimitation: "25",
                videoDuration: "1-3 minutes",
                price: 9.99,
            },
            {
                name: "25GB",
                storage: "25GB",
                listingLimitation: "50",
                videoDuration: "1-3 minutes",
                price: 19.99,
            },
            {
                name: "50GB",
                storage: "50GB",
                listingLimitation: "100",
                videoDuration: "3-5 minutes",
                price: 29.99,
            },
            {
                name: "100GB",
                storage: "100GB",
                listingLimitation: "250",
                videoDuration: "5-10 minutes",
                price: 39.99,
            },
        ],
    },
    mutations: {
        setStats(state, payload) {
            state.stats = payload;
        },
        setStoragePackages(state, payload) {
            state.storagePackages = payload;
        },
    },
    getters: {
        getStats: function (state) {
            return state.stats;
        },
        getStoragePackages: function (state) {
            return state.storagePackages;
        },
    },
    actions: {
        async getMetadataStats(ctx) {
            return axios
                .get(`/api/v1/storage/me/stats`)
                .then((res) => {
                    if (res.status === 200) {
                        let stats = res.data;
                        stats.rate = ((stats.used / stats.max) * 100).toFixed(2);
                        ctx.commit("setStats", stats);
                    } else {
                        ctx.commit("setStats", null);
                    }
                    return res;
                })
                .catch((err) => {
                    console.log("Fail to query data stats", err);
                });
        },
        async getSelfFiles(ctx, { start, size }) {
            return axios.get("/api/v1/storage/me/files", {
                params: {
                    start,
                    size,
                },
            });
        },
        async uploadFile(ctx, { file, name, config }) {
            let form = new FormData();
            form.append("file", file);
            form.append("name", name);

            return axios(
                Object.assign(
                    {
                        url: "/api/v1/storage/me/files",
                        method: "put",
                        data: form,
                        headers: { "Content-Type": "multipart/form-data" },
                    },
                    config || {}
                )
            );
        },
        async deleteFile(ctx, { file }) {
            return axios.delete(`/api/v1/storage/me/files/${file}`);
        },
    },
};
